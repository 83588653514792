<treo-card class="flex-col h-60 w-60 m-2">
    <div class="w-full h-40 w-full">
        <img class="w-full h-full" alt="File icon" style="object-fit: contain;"
             (click)="viewImage(file)"
             [src]="getUrl(file)">
    </div>
    <div class="p-3 flex-col">
        <p class="text-accent-500" style="font-size: 10px;" *ngIf="file?.type && file?.size">
            {{file?.type + ',' | uppercase}}
            {{ _formUtilsService.formatBytes(file?.size) | uppercase}}
        </p>
        <p class="font-bold">{{ file?.name?.length && !file.name.includes('undefined_name') ? file.name : '' }}</p>
        <div class="flex flex-wrap">
            <p class=" text-blue cursor-pointer mr-2" (click)="editFile.emit()">Edit Media</p>
            <p class=" text-blue cursor-pointer" (click)="deleteFile.emit()">Delete Media</p>
        </div>
    </div>
</treo-card>
