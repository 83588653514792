import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-attach-field-modal',
    templateUrl: './select-status-modal.component.html'
})
export class SelectStatusModalComponent implements OnInit {

    form: FormGroup;
    statuses = ['pending', 'processing', 'failed', 'completed', 'low_balance', 'no_package', 'package_limit', 'updating', 'budget', 'fee_budget'];

    constructor(
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<SelectStatusModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            status: []
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void{
        this.dialogRef.close(this.form.getRawValue());
    }
}
