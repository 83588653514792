import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {SelectStatusModalComponent} from './select-status-modal.component';
import {CustomSelectModule} from '../custom-select/custom-select.module';

@NgModule({
    declarations: [
        SelectStatusModalComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatProgressSpinnerModule,
        CustomSelectModule,
    ],
    exports: [
        SelectStatusModalComponent
    ]
})
export class SelectStatusModalModule {
}
