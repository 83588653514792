import {Layout} from 'app/layout/layout.types';
import {PERMISSIONS} from '../../shared/contstants/permissions.const';

// Theme type
export type Theme = 'auto' | 'dark' | 'light';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
    layout: Layout;
    theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * 'ConfigService' to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * 'ConfigService'.
 */

// change default theme type

export const appConfig: AppConfig = {
    layout: 'centered',
    theme: 'light'
};

export const backendNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/backend/dashboard',
    },
    {
        id: 'campaigns',
        title: 'Campaigns',
        type: 'basic',
        icon: 'mat_outline:list_alt',
        link: '/backend/campaigns'
    },
    {
        id: 'queues',
        title: 'Queues',
        type: 'basic',
        icon: 'mat_outline:border_outer',
        link: '/backend/queues'
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'mat_outline:people_outline',
        link: '/backend/users'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'mat_outline:autograph',
        link: '/backend/reports'
    },
    {
        id: 'payments',
        title: 'Payments',
        type: 'basic',
        icon: 'mat_outline:attach_money',
        link: '/backend/payments'
    },
    {
        id: 'sniper',
        title: 'Sniper',
        type: 'basic',
        icon: 'mat_outline:border_outer',
        link: '/backend/sniper'
    },
    {
        id: 'chats',
        title: 'Chats',
        type: 'basic',
        icon: 'mat_outline:chat',
        link: '/backend/chats'
    },
    {
        id: 'calendar',
        title: 'Calendar',
        type: 'basic',
        icon: 'mat_outline:calendar_today',
        link: '/backend/schedule'
    },
    {
        id: 'notifications',
        title: 'Notifications',
        type: 'basic',
        icon: 'mat_outline:notifications',
        link: '/backend/notifications'
    }
];
export const controlNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/control/dashboard',
    },
    {
        id: 'sites',
        title: 'Sites',
        type: 'basic',
        icon: 'mat_outline:list_alt',
        link: '/control/sites'
    },
    {
        id: 'social-networks',
        title: 'Social Networks',
        type: 'basic',
        icon: 'mat_outline:list_alt',
        link: '/control/social-networks'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'mat_outline:autograph',
        link: '/control/reports'
    },
    {
        id: 'roles',
        title: 'Roles',
        type: 'basic',
        icon: 'mat_outline:list',
        link: '/control/roles',
        permission: PERMISSIONS.ROLES.VIEW
    },
    {
        id: 'payments',
        title: 'Payments',
        type: 'basic',
        icon: 'mat_outline:attach_money',
        link: '/control/payments'
    },
    {
        id: 'sniper',
        title: 'Sniper',
        type: 'basic',
        icon: 'mat_outline:border_outer',
        link: '/control/sniper'
    },
    {
        id: 'chats',
        title: 'Chats',
        type: 'basic',
        icon: 'mat_outline:chat',
        link: '/control/chats'
    },
    {
        id: 'fields',
        title: 'Fields',
        type: 'basic',
        icon: 'mat_outline:list',
        link: '/control/fields'
    }
];
export const accountNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/account/dashboard',
    },
    {
        id: 'campaigns',
        title: 'Campaigns',
        type: 'basic',
        icon: 'mat_outline:list_alt',
        link: '/account/campaigns'
    },
    {
        id: 'queues',
        title: 'Queues',
        type: 'basic',
        icon: 'mat_outline:border_outer',
        link: '/account/queues'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'mat_outline:autograph',
        link: '/account/reports'
    },
    {
        id: 'payments',
        title: 'Payments',
        type: 'basic',
        icon: 'mat_outline:attach_money',
        link: '/account/payments'
    },
    {
        id: 'sniper',
        title: 'Sniper',
        type: 'basic',
        icon: 'mat_outline:border_outer',
        link: '/account/sniper'
    },
    {
        id: 'chats',
        title: 'Chats',
        type: 'basic',
        icon: 'mat_outline:chat',
        link: '/account/chats'
    },
    {
        id: 'Calendar',
        title: 'Calendar',
        type: 'basic',
        icon: 'mat_outline:calendar_today',
        link: '/account/schedule'
    }
];
