import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {ViewImageModalComponent} from '../view-image-modal/view-image-modal.component';

@Component({
    selector: 'app-view-attachments',
    templateUrl: './view-attachments.component.html',
    styleUrls: ['./view-attachments.component.scss']
})
export class ViewAttachmentsComponent implements OnInit, OnDestroy {

    @Input() currentAttachments: any = null;
    @Input() showDelete = true;

    _unsubscribeAll = new Subject();

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    deleteAttachments(index: number, array: any = []): void {
        if (array && array.length) {
            array.splice(index, 1);
        }
    }

    viewImage(file: any): void {
        this.dialog.open(ViewImageModalComponent, {
            data: file
        });
    }
}
