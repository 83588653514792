export const COMMON: any = {
    CHANNEL_TYPES: ['mail', 'sms', 'telegram'],
    NOTIFICATIONS: ['waiting', 'processing', 'completed', 'cancelled'],
    NOTIFICATIONS_ITEMS: ['waiting', 'processing', 'completed', 'cancelled', 'read', 'delivered', 'pending'],
    PAYMENT_STATUSES: ['failed', 'refunded', 'paid', 'processing', 'unpaid', 'declined', 'forbidden', 'cancelled', 'dispute'],
    NOTIFICATIONS_FOR_USERS: ['pending', 'processing', 'delivered', 'failed', 'cancelled', 'read'],

    SITE_SECTION: ['backend', 'control', 'account'],
    SITE_SECTION_CONTROL: 'control',
    SITE_SECTION_BACKEND: 'backend',
    SITE_SECTION_ACCOUNT: 'account',
    COOKIE_KEY: ['GA_config'],

    CARDS_ICONS: [
        {
            name: 'visa',
            path: 'assets/icons/visa.svg'
        },
        {
            name: 'mastercard',
            path: 'assets/icons/mastercard.svg'
        },
        {
            name: 'amex',
            path: 'assets/icons/amex.svg'
        },
        {
            name: 'unionpay',
            path: 'assets/icons/unionpay.svg'
        },
    ],

    LOGO_PATCH: 'assets/images/logo/logo.png',

    PRIORITIES: [{id: 0, name: 'low'}, {id: 1, name: 'medium'}, {id: 2, name: 'high'}],

    TABLE: {
        PARAMS: {
            // 'id[orderBy]': 'desc'
            order: 'desc',
            orderBy: 'id'
        },
        PAGINATOR: {
            PAGE_SIZE: 15,
            PAGE_OPTIONS: [5, 15, 50, 100, 200, 500]
        },
        SORT: {
            DIRECTIONS: [
                {
                    id: 'asc',
                    name: 'Ascending'
                },
                {
                    id: 'desc',
                    name: 'Descending'
                }
            ]
        }

    }
};
