<form class="flex align-items-center w-full"
      [formGroup]="selectForm" [ngClass]="{'custom-select-min-h-1': isAutoOpen}">
    <mat-form-field class="w-full"
                    [ngClass]="{'without-margin' : showPlaceholder}">
        <mat-label [ngClass]="{'d-none' : showPlaceholder}">{{label}}</mat-label>
        <mat-select [multiple]="multi" #matSelectMulti
                    [disabled]="selectForm.get(formField).disabled"
                    [required]="required"
                    [placeholder]="placeholder"
                    [formControlName]="formField"
                    (openedChange)="opened($event)"
                    (selectionChange)="checkedData($event.value)">
            <ng-container *ngIf="showSearchField">
                <input placeholder="Search" class="select-search" type="text"
                       (mousedown)="selectForm.get(formField).disable()"
                       [formControl]="search">
            </ng-container>
            <mat-checkbox id="select-checkbox" value="0" (click)="toggleAllSelection()"
                          *ngIf="multi && showToggleAllButton && selectedItems && selectedItems.length && !arrayItemsEmpty">
                Select all
            </mat-checkbox>
            <ng-container *ngIf="isGroup; else defaultOption">

                <!--                                <ng-container *ngIf="showAddCategory">-->
                <!--                                    <ng-container-->
                <!--                                            *ngIf="!isShowCategoryForm && selectedItems && selectedItems.length && !arrayItemsEmpty;">-->
                <!--                                        <mat-option class="hide-checkbox pl-8" (click)="showCategoryForm(null)">-->
                <!--                                            <span class="lick">Add new category</span>-->
                <!--                                        </mat-option>-->
                <!--                                    </ng-container>-->
                <!--                                    <ng-container-->
                <!--                                            *ngIf="isShowCategoryForm && selectedItems && selectedItems.length && !arrayItemsEmpty;">-->
                <!--                                        <ng-container [ngTemplateOutlet]="categoryForm"-->
                <!--                                                      [ngTemplateOutletContext]="{ id: null }"></ng-container>-->
                <!--                                    </ng-container>-->
                <!--                                </ng-container>-->
                <ng-container *ngFor="let group of selectedItems">
                    <ng-container *ngIf="group">
                        <mat-optgroup [label]="group.name"
                                      [disabled]="!group[groupField] || !group[groupField].length">
                            <mat-option *ngFor="let _item of group[groupField]" [value]="getValue(_item)"
                                        class="pl-8">
                                {{ _item.name ? _item.name
                                : _item.label ? _item.label
                                    : _item.title ? _item.title
                                        : _item }}
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                    <mat-option class="text-center hide-checkbox" *ngIf="!group" disabled>
                        No records found!
                    </mat-option>
                </ng-container>
            </ng-container>
            <ng-template #defaultOption>
                <ng-container *ngIf="selectedItems && selectedItems.length">
                    <ng-container *ngFor="let _item of selectedItems;">
                        <ng-container *ngIf="_item && ((currentId && currentId !== getValue(_item)) || !currentId)">
                            <mat-option [value]="getValue(_item)">
                                <ng-container *ngIf="changeDefaultTitle; else defaultTitle">
                                    {{_item.name + ' ' + _item.value}}
                                </ng-container>
                                <ng-template #defaultTitle>
                                    {{ _item.name ? _item.name
                                    : _item.label ? _item.label
                                        : _item.title ? _item.title
                                            : _item.email ? _item.email
                                                : _item.subject ? _item.subject
                                                    : _item }}
                                    <span *ngIf="showChannelsInName">
                                        <ng-container *ngFor="let channel of _item.channels; let last = last">
                                            <span>{{channel}}<span *ngIf="!last">,</span></span>
                                        </ng-container>
                                    </span>
                                </ng-template>
                            </mat-option>
                        </ng-container>
                        <mat-option class="text-center hide-checkbox" *ngIf="!showAutoLoading && !_item" disabled>
                            No records found!
                        </mat-option>
                    </ng-container>
                </ng-container>
            </ng-template>
            <p class="h-10 text-center leading-10" *ngIf="!selectedItems || !selectedItems.length">No records found!</p>

            <div class="h-10 flex justify-content-center" *ngIf="showAutoLoading">
                <mat-progress-spinner
                        *ngIf="showAutoLoading"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
        </mat-select>
        <mat-error *ngIf="selectForm.get(formField).invalid && selectForm.get(formField).touched">
            {{_formUtilsService.getErrorMessage(selectForm, formField)}}
        </mat-error>
    </mat-form-field>
    <ng-container *ngIf="showClearIcon && (selectForm.get(formField).value || selectForm.get(formField).value === 0)">
        <button class="previous-button mt-2 ml-1"
                mat-icon-button
                (click)="clearField(selectForm.get(formField))">
            <mat-icon svgIcon="mat_outline:close"></mat-icon>
        </button>
    </ng-container>
</form>
