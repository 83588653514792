import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
    ActivatedRoute,
    Data,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TreoMediaWatcherService} from '@treo/services/media-watcher';
import {TreoNavigationService} from '@treo/components/navigation';
import {environment} from '../../../../../environments/environment';
import {StorageUtilsService} from '../../../../core/utils/storage.utils.service';
import {UserService} from '../../../common/user/user.service';
import {FormUtilsService} from '../../../../core/utils/form-utils.service';
import {PayUtilsService} from '../../../../core/utils/pay-utils.service';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    styleUrls: ['./dense.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
    data: any;
    balance: any;
    isScreenSmall: boolean;
    loading: boolean;
    currentSiteName = environment.currentSiteName;

    @HostBinding('class.fixed-header')
    fixedHeader: boolean;

    @HostBinding('class.fixed-footer')
    fixedFooter: boolean;

    navigationAppearance: 'classic' | 'dense';

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {Router} _router
     * @param _storageUtilsService
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     * @param {TreoNavigationService} _treoNavigationService
     * @param _userService
     * @param _formUtilsService
     * @param _payUtilsService
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        public _storageUtilsService: StorageUtilsService,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _treoNavigationService: TreoNavigationService,
        private _userService: UserService,
        public _formUtilsService: FormUtilsService,
        private _payUtilsService: PayUtilsService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.fixedHeader = false;
        this.fixedFooter = false;
        this.navigationAppearance = 'dense';
        this._userService.user$
            .subscribe(res => {
                this.balance = res?.data?.balance;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the resolved route data
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to media changes
        this._treoMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the breakpoint is 'lt-md'
                this.isScreenSmall = matchingAliases.includes('lt-md');
            });

        this.loading = false;
        this._router.events.subscribe((event: any) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    openPayModal(): void {

        const url = environment.payFormUrl;

        // for local
        // const url = 'http://localhost:4100';

        const config = this._storageUtilsService.config;
        if (!config?.balanceFormId) {
            return;
        }
        const data = {
            parentUrl: environment.apiUrl + '/api/profile/routes/',
            parentDomain: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
            type: 'modal',
            form: config.balanceFormId,
            client_id: config.client_id,
            authToken: localStorage.getItem(environment.authTokenKey) || undefined,
            url: url,
            returnUrl: window.location.href.split('?')[0],
            cancelUrl: window.location.href.split('?')[0],
        };
        this._payUtilsService.showIframe(data);
    }

    /**
     * Toggle navigation
     *
     * @param key
     */
    toggleNavigation(key): void {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(key);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this.navigationAppearance === 'classic' ? this.navigationAppearance = 'dense' : this.navigationAppearance = 'classic';
    }
}
