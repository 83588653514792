import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TreoCardModule} from '../../../../@treo/components/card';
import {NgxImageCompressService} from 'ngx-image-compress';

import {UploadImageComponent} from './upload-image.component';
import {DndDirective} from '../../directive/dnd.directive';

@NgModule({
    declarations: [
        UploadImageComponent,
        DndDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        TreoCardModule
    ],
    providers: [
        NgxImageCompressService
    ],
    exports: [
        UploadImageComponent
    ]
})
export class UploadImageModule {
}
