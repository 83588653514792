import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from 'app/layout/layout.component';
import {EmptyLayoutModule} from 'app/layout/layouts/empty/empty.module';
import {CenteredLayoutModule} from './layouts/horizontal/centered/centered.module';
import {DenseLayoutModule} from './layouts/vertical/dense/dense.module';

const modules = [
    // Empty
    EmptyLayoutModule,
    // Horizontal navigation
    CenteredLayoutModule,

    // Vertical navigation
    DenseLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        CommonModule,
        ...modules
    ],
    exports: [
        ...modules
    ]
})
export class LayoutModule {
}
