// Angular
import {Component, OnInit, ChangeDetectionStrategy, Input, ViewChild} from '@angular/core';

import {FormUtilsService} from '../../../core/utils/form-utils.service';
import {AuthService} from '../../../core/auth/auth.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class TextEditorComponent implements OnInit {

    url = environment.apiUrl;

    @Input() ckForm;
    @Input() field: any = 'text';

    @ViewChild('editor')
    private editor: any;

    defaultButtons = 'source,bold,italic,underline,strikethrough,ul,ol,left,font,brush,image,link,undo,redo';

    // dlucky buttonsXS: [
    //             'bold',
    //             'image',
    //             'brush',
    //             'left',
    //             'center',
    //             'right',
    //             'dots'
    //         ],

    // TODO ошибки - что выводить и какие могут быть

    configs = {
        enableDragAndDropFileToEditor: true,
        buttons: this.defaultButtons,
        buttonsXS: this.defaultButtons,
        buttonsSM: this.defaultButtons,
        buttonsMD: this.defaultButtons,
        uploader: {
            url: this.url + '/uploads/images',
            headers: this.setHeaders(),
            filesVariableName: (data) => {
                console.log(data);
                return 'file';
            },
            prepareData: (data) => {
                return data;
            },
            isSuccess: (data) => {
                return data;
            },
            getMsg: (resp) => {
                return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
            },
            process: (resp) => {
                const arr = [];
                arr.push(resp.data.file.url);
                return {
                    path: resp.data.file.url,
                    error: resp.data.file.message,
                    msg: resp.msg
                };
            },
            error: (e) => {
                console.log(e);
            },
            defaultHandlerSuccess: (data) => {
                const d = this.editor.editor.selection;
                d.insertImage(data.path);
            },
            defaultHandlerError: (resp) => {
                console.log(resp);
            }
        }
    };

    constructor(private _formUtilsService: FormUtilsService,
                private _authService: AuthService) {
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log(CKEDITOR);
        // CKEDITOR.replace('editor1');
    }

    setHeaders(): any {
        const headerConfig = {
            'Authorization': 'Bearer ' + this._authService.accessToken,

        };
        if (window.location.hostname){
            headerConfig['x-api-domain'] = window.location.hostname;
        }
        return headerConfig;
    }

    // onReadyCkeditor(loader): void {
    //     this._formUtilsService.onReadyCkeditor(loader);
    // }

}
