import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {ViewImageModalComponent} from './view-image-modal.component';

@NgModule({
    declarations: [
        ViewImageModalComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        ViewImageModalComponent
    ]
})
export class ViewImageModalModule {
}
