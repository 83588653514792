<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!--Centered-->
<centered-layout *ngIf="layout === 'centered'"></centered-layout>
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- futuristic -->
<dense-layout *ngIf="layout === 'dense'"></dense-layout>
