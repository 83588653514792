<!-- Navigation -->
<treo-vertical-navigation class="theme-dark"
                          [appearance]="'dense'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.default"
                          [opened]="!isScreenSmall">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <a href="/" class="cursor-pointer">
                <img [src]="_storageUtilsService.getLogo()" alt="logo">
            </a>
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Navigation appearance toggle button -->
        <!--        <button class="navigation-appearance-toggle-button"-->
        <!--                [ngClass]="{mirror: navigationAppearance === 'dense'}"-->
        <!--                mat-icon-button-->
        <!--                (click)="toggleNavigationAppearance()">-->
        <!--            <mat-icon [svgIcon]="'menu_open'"></mat-icon>-->
        <!--        </button>-->

        <!-- Spacer -->
        <div class="spacer"></div>
        <ng-container
                *ngIf="balance || balance === 0 || balance === '0'">
            <div class="flex" [ngClass]="{'cursor-pointer': _storageUtilsService?.config?.balanceFormId}" (click)="openPayModal()">
                <div class="text-3xl" [style]="{'font-weight': '500'}">
                    ${{_formUtilsService.formatMoney(balance)}}
                </div>
            </div>
        </ng-container>
        <div>
            <button class="icon-button"
                    mat-icon-button>
                        <span class="avatar">
                             <a href="https://support.dluckyslots.com/" target="_blank">
                                 <mat-icon class="cursor-pointer m-1" svgIcon="mat_outline:contact_support"></mat-icon>
                             </a>
                        </span>
            </button>
        </div>

        <!-- User -->
        <user></user>

    </div>

    <!-- Content -->
    <div class="content">
        <mat-progress-bar [mode]="'indeterminate'" value="40" *ngIf="loading"></mat-progress-bar>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        {{currentSiteName}} &copy; {{currentYear}}
    </div>

</div>
