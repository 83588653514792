import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormUtilsService} from '../../../core/utils/form-utils.service';
import {ViewImageModalComponent} from '../view-image-modal/view-image-modal.component';

@Component({
    selector: 'app-view-image',
    templateUrl: './view-image.component.html'
})
export class ViewImageComponent implements OnInit {

    @Input() file: any;

    @Output() deleteFile?: any = new EventEmitter();
    @Output() editFile?: any = new EventEmitter();

    constructor(public _formUtilsService: FormUtilsService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    viewImage(file: any): void {
        const image = file?.imageUrl ? file?.imageUrl : file;
        this.dialog.open(ViewImageModalComponent, {
            data: image
        });
    }

    getUrl(file): any {
        return file?.imageUrl ? file?.imageUrl : file;
    }

}
