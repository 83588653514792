import {ElementRef, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {IframeResizerDirective} from '../../shared/directive/iframe-resizer.directive';
import {UserService} from '../../layout/common/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class PayUtilsService {

    config: any;

    constructor(private _userService: UserService) {
    }

    showIframe(config: any): void {
        const payScript = document.getElementById('pay-script');
        if (payScript) {
            payScript.remove();
        }
        this.config = config;
        const script = document.createElement('script');
        script.id = 'pay-script';
        script.src = environment.payFormUrl + 'pay.js';
        script.setAttribute('config', JSON.stringify(config));
        document.head.appendChild(script);
        window.addEventListener('message', this.receiveMessage.bind(this), false);
    }

    receiveMessage(event): any {
        if (event.origin === environment.payFormUrl.slice(0, -1) || event.origin === 'http://localhost:4100') {
            let eventData = event.data;
            if (eventData && typeof eventData === 'string') {
                eventData = JSON.parse(eventData);
            }
            if (eventData.type) {
                const messageData = eventData.data;
                switch (eventData.type) {
                    case 'initForm':
                        this.addResizeDirective();
                        // this.matomoTracker.trackVisibleContentImpressions(true, 1);
                        // this.matomoTracker.setEcommerceView(messageData.id, messageData.name, '', messageData.total);
                        // this.matomoTracker.trackPageView();
                        break;
                    case 'resize':
                        this.changeHeight(eventData.height);
                        break;
                    case 'submitForm':
                        // this.matomoTracker.trackEvent('Form', 'Submit Payment form', messageData.name);
                        // this.matomoTracker.trackEcommerceCartUpdate(parseFloat(messageData.total));
                        // this.matomoTracker.trackPageView();
                        break;
                    case 'FormError':
                        // this.matomoTracker.trackEvent('Form', 'Form action', messageData.error);
                        break;
                    case 'successForm':
                        // this.matomoTracker.trackEcommerceOrder(messageData.payment_id, messageData.total);
                        // this.matomoTracker.trackPageView();
                        if (this.config?.balance) {
                            this._userService.loadUser().subscribe();
                        }
                        break;
                    case 'addToken':
                        // this.addPushToken(messageData.token);
                        // this.matomoTracker.trackEvent('Form', 'Set push token', messageData.token);
                        // this.matomoTracker.trackPageView();
                        break;
                }
            }
        }
    }

    addResizeDirective(): void {
        const iframePay = document.getElementById('iframe-pay') as HTMLElement;
        const iframeElementRef = new ElementRef(iframePay);
        const iframeResizer = new IframeResizerDirective(iframeElementRef);
        iframeResizer.ngAfterViewInit(false);
    }

    changeHeight(height): void {
        const iframePay = document.getElementById('iframe-pay') as HTMLElement;
        iframePay['height'] = height;
    }
}
