import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatMenuModule} from '@angular/material/menu';

import {ActionNotificationModule} from './components/action-natification/action-notification.module';
import {UploadImageModule} from './components/upload-image/upload-image.module';
import {ViewImageModule} from './components/view-image/view-image.module';
import {TextEditorModule} from './components/text-editor/text-editor.module';
import {BackButtonModule} from './components/back-button/back-button.module';
import {ViewAttachmentsModule} from './components/view-attachments/view-attachments.module';

import {CheckScrollDirective} from './directive/check-scroll.directive';

import {EmbedVideoService} from './services/video.service';


import {StripHtmlPipe} from './pipe/strip-html.pipe';
import {ScrollToModule} from './components/scroll-to/scroll-to.module';
import {ViewMessageModule} from './components/view-message/view-message.module';
import {FabMenuModule} from './components/mat-fab-menu/fab-menu.module';
import {CustomSelectModule} from './components/custom-select/custom-select.module';
import {ImageEditorModule} from './components/image-editor/image-editor.module';
import {ViewImageModalModule} from './components/view-image-modal/view-image-modal.module';
import {IframeResizerDirective} from './directive/iframe-resizer.directive';
import {SelectStatusModalModule} from './components/select-status-modal/select-status-modal.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UploadImageModule,
        ViewImageModule,
        TextEditorModule,
        MatButtonToggleModule,
        MatMenuModule,
        BackButtonModule,
        ScrollToModule,
        ViewAttachmentsModule,
        ViewMessageModule,
        FabMenuModule,
        CustomSelectModule,
        ImageEditorModule,
        ViewImageModalModule,
        SelectStatusModalModule
    ],
    declarations: [
        CheckScrollDirective,
        StripHtmlPipe,
        IframeResizerDirective
    ],
    providers: [
        EmbedVideoService,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ActionNotificationModule,
        CheckScrollDirective,
        UploadImageModule,
        ViewImageModule,
        TextEditorModule,
        BackButtonModule,
        StripHtmlPipe,
        ScrollToModule,
        ViewAttachmentsModule,
        ViewMessageModule,
        FabMenuModule,
        CustomSelectModule,
        ViewImageModalModule,
        SelectStatusModalModule
    ]
})
export class SharedModule {
}
