import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';

import {environment} from '../../../environments/environment';
import {WINDOW} from './window.provider';

@Injectable()
export class Interceptor implements HttpInterceptor {
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     * @param window
     */
    constructor(
        private _authService: AuthService,
        @Inject(WINDOW) private window: Window
    ) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // let domain = this.window.location.hostname;
        // if (domain.includes('localhost')) {
        // const domain = 'client2.adposting.dev.zenzenzen.net';
        const domain = 'client.adposting.dev.zenzenzen.net';
        // }

        const headerConfig = {
            'x-api-domain': domain,
            'Accept': 'application/json'
        };

        if (this._authService.accessToken) {
            headerConfig['Authorization'] = 'Bearer ' + this._authService.accessToken;
        }

        req = req.clone({
            setHeaders: headerConfig
        });

        return next.handle(req).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            })
        );
    }
}
