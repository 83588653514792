import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {FormUtilsService} from '../../../core/utils/form-utils.service';
import {NotificationUtilsService} from '../../../core/utils/notification-utils.service';

@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {

    @Input() form;
    @Input() field = 'elements';

    loader = false;
    disabled = false;
    compressionFiles = [];

    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private _httpClient: HttpClient,
                private _formUtilsService: FormUtilsService,
                private _notificationUtilsService: NotificationUtilsService) {
    }

    ngOnInit(): void {
    }

    prepareFilesList(files: Array<any>): any {
        if (!files) {
            return;
        }
        for (const file of files) {
            const render = new FileReader();
            render.onload = (e) => {
                this._formUtilsService.compressFiles(file, e.target.result, this.form.get(this.field));
            };
            render.readAsDataURL(file);
        }
    }

    /**
     * on file drop handler
     */
    onFileDropped($event): void {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files): void {
        this.prepareFilesList(files);
    }

}
