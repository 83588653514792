<form class="flex flex-col w-full"
      [formGroup]="form">
    <div class="w-full flex align-items-center">
        <!--        <div class="imagePreview col-lg-12" *ngIf="imageURL && imageURL !== ''">-->
        <!--            <img [src]="imageURL" class="w-100" [alt]="userForm.value.name">-->
        <!--        </div>-->

        <div class="container xs:w-full" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" accept="image/*" #fileDropRef id="fileDropRef"
                   multiple
                   (change)="fileBrowseHandler($event.target?.files)"/>
            <mat-icon svgIcon="mat_outline:upload_file" class="icon-size">
            </mat-icon>
            <h3>Drag and drop file here</h3>
            <h3>or</h3>
            <label for="fileDropRef">Browse for file</label>
        </div>
    </div>
</form>
