<ng-container *ngIf="currentAttachments && currentAttachments.length > 0">

    <div class="attachments py-4">

        <div class="title mt-0">
            <div>{{currentAttachments.length}} Attachments</div>
        </div>

        <div class="files">

            <div class="file"
                 *ngFor="let attachment of currentAttachments; let i = index;">

                <img class="preview-image cursor-pointer"
                     (click)="viewImage(attachment.url)"
                     *ngIf="attachment.url"
                     [src]="attachment.url">

                <div class="file-info">

                    <div class="file-name"
                         [title]="attachment.filename">
                        {{attachment.filename}}
                    </div>

                    <div class="file-size"
                         [title]="attachment.size">
                        {{attachment.size / 1000 | number:'1.0-2'}} KB
                    </div>

                </div>

                <ng-container *ngIf="showDelete">
                    <button mat-icon-button
                            color="warn"
                            class="border-0 icon-size ml-2"
                            (click)="deleteAttachments(i, currentAttachments)">
                        <mat-icon
                                [svgIcon]="'delete'"></mat-icon>
                    </button>
                </ng-container>

            </div>

        </div>

    </div>

</ng-container>
