<div class="w-100 xs:w-full min-w-64">
    <div class="flex">
        <app-custom-select [selectForm]="form"
                           [formField]="'status'"
                           [label]="'Status'"
                           [items]="statuses"
                           [multi]="false">
        </app-custom-select>
    </div>
    <div class="w-full py-4 flex justify-content-end">
        <button mat-flat-button
                (click)="save()"
                [color]="'primary'"
                type="submit">
            Update
        </button>
    </div>
</div>
