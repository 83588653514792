import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastUiImageEditorComponent} from 'ngx-tui-image-editor';

@Component({
    selector: 'app-view-image',
    templateUrl: './image-editor.component.html'
})
export class ImageEditorComponent implements OnInit {

    imageUrl;

    @ViewChild(ToastUiImageEditorComponent) editorComponent: ToastUiImageEditorComponent;

    constructor(public dialogRef: MatDialogRef<ImageEditorComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.imageUrl = this.data;
    }

    save(): void {
        this.dialogRef.close({data: this.editorComponent.editorInstance.toDataURL()});
    }

    close(): void {
        this.dialogRef.close();
    }
}
