<div class="flex flex-col w-full">

    <ng-container *ngIf="imageUrl?.length">
        <tui-image-editor
            style="width: 65vw;height: 65vh;"
            [options]="{includeUI: {
                        loadImage: {
                          path: imageUrl,
                          name: 'image'
                        }}}">
        </tui-image-editor>
    </ng-container>


    <div class="flex items-center justify-between w-full border-t py-4">
        <button type="button"
                class="mr-4"
                mat-raised-button
                color="warn"
                (click)="close()">
            Close
        </button>
        <button type="button"
                mat-raised-button
                color="accent"
                (click)="save()">
            Save
        </button>
    </div>

</div>
