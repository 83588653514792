import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

import {environment} from '../../../environments/environment';
import {COMMON} from '../../shared/contstants/common.const';

@Injectable({
    providedIn: 'root'
})
export class StorageUtilsService {

    private _changeSiteSection: BehaviorSubject<boolean | null>;
    siteSection = COMMON.SITE_SECTION;

    /**
     * Constructor
     */
    constructor(private _cookieService: CookieService,
                private _titleService: Title) {

        this._changeSiteSection = new BehaviorSubject(false);
    }


// SECTION SITE

    get changeSiteSection$(): any {
        return this._changeSiteSection.asObservable();
    }

    setSection(section: string): void {
        this._changeSiteSection.next(true);
        environment.apiUrlSection = environment.apiUrl + '/api/' + section;
        sessionStorage['section'] = section;
    }

    resetSection(): void {
        const sections = this.siteSection;
        sections.map(section => {
            if (sessionStorage[section]) {
                delete sessionStorage[section];
            }
        });
    }

    getSection(): string {
        return sessionStorage['section'];
    }

    get isBackend(): boolean {
        return sessionStorage['section'] === COMMON.SITE_SECTION_BACKEND;
    }

    get isAccount(): boolean {
        return sessionStorage['section'] === COMMON.SITE_SECTION_ACCOUNT;
    }


    urlDependingSection(val?: number): string | null {
        const section = this.getSection();
        if (!section) {
            return null;
        }
        let url = '';
        switch (true) {
            case section === COMMON.SITE_SECTION_ACCOUNT: {
                url = this.urlWithProfile();
                break;
            }
            default : {
                if (this.check('siteId') && val) {
                    url = this.urlWithSite();
                } else {
                    url = this.urlSection();
                }
                break;
            }
        }
        return url;
    }

    urlEmpty(): string {
        return environment.apiUrl + '/api';
    }

    urlWithProfile(): string {
        return environment.apiUrl + '/api/profile';
    }

    urlSection(): string {
        return environment.apiUrlSection;
    }

    urlWithSite(): string {
        return `${environment.apiUrlSection}/sites/${this.get('siteId')}`;
    }

    clear(): void {
        this._cookieService.deleteAll();
    }

    check(field): any {
        return this._cookieService.check(COMMON.COOKIE_KEY + field);
    }

    set(field: string, data: any): void {
        this._cookieService.set(COMMON.COOKIE_KEY + field, JSON.stringify(data), null, '/');
    }

    get(field: string): any {
        return JSON.parse(this._cookieService.get(COMMON.COOKIE_KEY + field));
    }

    remove(field): void {
        this._cookieService.delete(COMMON.COOKIE_KEY + field, '/');
    }

    getLogo(): string {
        if (this.config && this.config.data) {
            return this.config.data.logo || COMMON.LOGO_PATCH;
        }
        return COMMON.LOGO_PATCH;
    }

    // CONFIG SITE

    setConfig(data: any): void {
        sessionStorage['config'] = JSON.stringify(data);
        this.checkConfig();
    }

    checkConfig(): void {
        const config = this.config;
        const href = config?.data?.favicon ? config?.data?.favicon : 'assets/images/logo/logo.png';
        if (config && config.name) {
            environment.currentSiteName = config.name;
            this._titleService.setTitle(config.name);
        }
        const favIcon = document.getElementById('appFavicon');
        favIcon['href'] = href;
    }

    resetConfig(): void {
        delete sessionStorage['config'];
    }

    get config(): any {
        if (sessionStorage['config']) {
            return JSON.parse(sessionStorage['config']);
        }
    }
}

